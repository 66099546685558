import React from "react"
import { FormattedMessage } from "react-intl"
import { Table } from 'antd'

import Seo from '../components/seo'
import Icon from '../components/iconfontCpt'
import Layout from "../components/layout"

import '../style/certification.scoped.scss'

const columnsArr = ['version', 'company', 'openGauss', 'created', 'deadline', 'downloadUrl']
const Certification = ({ pageContext }) => {
  const pageLang = pageContext.lang

  const columns = columnsArr.map((item, idx) => {
    const colItem = {
      title: <FormattedMessage id={`certification.tableCol${idx + 1}`} />,
      dataIndex: item,
      key: item
    }
    if (item === 'downloadUrl') {
      colItem.render = text => <a className="download-link" target="_blank" href={text}><Icon type="icon-download" size={10}/><span className="more-text"><FormattedMessage id="certification.download" /></span></a>
    }
    return colItem
  })
  const dataSource = [
    {
      key: '1',
      version: 'MogDB 2.0.1',
      company: '云和恩墨（北京）信息技术有限公司',
      openGauss: 'openGauss 2.0.0',
      created: '2021-08-25',
      deadline: '2024-03-31',
      downloadUrl: 'https://distributioncertification-beijing4.obs.myhuaweicloud.com/MogDB%202.0.1/Certificate.pdf'
    }
  ]
  
  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: Certification"/>
      <div className="w-banner-container">
        <div className="w-banner-box"><div className="w-banner-text">openGauss <FormattedMessage id="certification.pageTitle" /></div></div>
      </div>
      <div className="certification-container">
        <div className="type-title"><FormattedMessage id="certification.tableTitle" /></div>
        <Table dataSource={dataSource} className="w-table" columns={columns} pagination={false} />
      </div>
      <div className="mobile-certification-container">
        <div className="type-title"><FormattedMessage id="certification.tableTitle" /></div>
        {columnsArr.map((item, idx) => (
          item === 'downloadUrl' ?
          <a className="download-link" key={idx} target="_blank" href={dataSource[0][item]}><Icon type="icon-download" size={10}/><span className="more-text"><FormattedMessage id="certification.download" /></span></a>:
          <div className="info-box" key={idx}>
            <div className="info-label"><FormattedMessage id={`certification.tableCol${idx + 1}`} /></div>
            <div className="info-value">{dataSource[0][item]}</div>
          </div>
        ))}
      </div>
    </Layout>
  )
}
export default Certification